interface CompCard {
  name: string;
  url: string;
}

const cards: CompCard[] = [
  {
    name: "The General Insurance",
    url: "https://firebasestorage.googleapis.com/v0/b/peters-website-auto-insurance.appspot.com/o/the-general-logo.jpg?alt=media&token=17752bab-74a6-4267-b111-9ea67e0ca191",
  },
  {
    name: "Progressive",
    url: "https://firebasestorage.googleapis.com/v0/b/peters-website-auto-insurance.appspot.com/o/progressive.png?alt=media&token=caa5cd0e-a27b-4d08-a8a1-c6baf09b43a0",
  },
  {
    name: "National General",
    url: "https://firebasestorage.googleapis.com/v0/b/peters-website-auto-insurance.appspot.com/o/national-general-auto-ins.jpg?alt=media&token=ff63ff5c-e619-47af-9e20-84599c58848d",
  },
  {
    name: "Infinity",
    url: "https://firebasestorage.googleapis.com/v0/b/peters-website-auto-insurance.appspot.com/o/infinity.png?alt=media&token=b9c116d9-cd41-4b29-b48a-97b05ba6f0c9",
  },
  {
    name: "Gainsco",
    url: "https://firebasestorage.googleapis.com/v0/b/peters-website-auto-insurance.appspot.com/o/gainsco-auto-insurance-logo-vector.png?alt=media&token=070691b5-3fe6-4491-8676-a70401eb8e91",
  },
  {
    name: "Bristol West",
    url: "https://firebasestorage.googleapis.com/v0/b/peters-website-auto-insurance.appspot.com/o/bristolwest-1.png?alt=media&token=849189c4-27da-4ef2-98ac-ede82980565b",
  },
  {
    name: "Mendota",
    url: "https://firebasestorage.googleapis.com/v0/b/peters-website-auto-insurance.appspot.com/o/carrierLogo_MendotaInsurance_fullwidth_16-9.png?alt=media&token=921c5784-2f73-4966-845e-cbc9cfe53b4a",
  },
];

export { cards, CompCard };
