interface Service {
  title: string;
  url: string;
  sub: string;
  item: string;
  itemTwo: string;
  itemThree: string;
  itemFour: string;
  offset: string;
  id: number;
}

const services: Service[] = [
  {
    title: "Home/ Rental Insurance",
    url: "https://firebasestorage.googleapis.com/v0/b/peters-website-auto-insurance.appspot.com/o/home-insurance.jpg?alt=media&token=e25c7484-e41a-4567-a232-38aff457caf8",
    sub: "Owning or renting is an important step in life. Most people don't think about how or what would even be protected. We can help show you all the great benefits that come along with being insured.",
    item: "Protection for your main Home",
    itemTwo: "Liability coverage",
    itemThree:
      "Coverage for your home’s contents like computers, furniture, etc.",
    itemFour: "Great savings and discounts by bundling",
    offset: "0",
    id: 0,
  },
  {
    title: "Auto Insurance",
    url: "https://firebasestorage.googleapis.com/v0/b/peters-website-auto-insurance.appspot.com/o/auto-insurance.jpg?alt=media&token=4b430354-835d-43b0-ac61-1e6df7209bed",
    sub: "Auto insurance offers lots of customization when it comes to finding the right coverages. We can help anyone from new teenage driver to DUI's. We have options and want to find you the best bang for your buck!",
    item: "Coverage for damage or theft of your vehicle",
    itemTwo: "Bodily injury or property damage",
    itemThree: "Medical coverage in the case of injuries",
    itemFour: "Special Cases: DUI, Accidents, tickets, suspension, etc.",
    offset: "1",
    id: 1,
  },
  {
    title: "Commercial Insurance",
    url: "https://firebasestorage.googleapis.com/v0/b/peters-website-auto-insurance.appspot.com/o/commercial-insurance.jpg?alt=media&token=3b367ce7-1301-4472-8ce6-4fe35ca92b4e",
    sub: "Owning a business is a huge accomplishment, but also has many risks involved. We know all about running a business and how to keep it protected in case of those risks. There are many things to consider that we can help shed some light on.",
    item: "General liability insurance",
    itemTwo: "Property insurance",
    itemThree: "Learn how claims work and how to use the right tools",
    itemFour: "Workers Compensation",
    offset: "0",
    id: 2,
  },
  {
    title: "Health Insurance",
    url: "https://firebasestorage.googleapis.com/v0/b/peters-website-auto-insurance.appspot.com/o/health-insurance.jpg?alt=media&token=90bf6105-fee4-456e-aa45-5fc0fa035057",
    sub: "When it comes to your health insurance, you want to be educated to make the best decision. We have years of experience in the health industry and can help equip you with the right knowledge so you can make the best decision for you or your family.",
    item: "ACA has plenty of options",
    itemTwo: "May be elegible for a discounted rate(subsidy)",
    itemThree: "Emergency Accident Coverage",
    itemFour: "Knowledge is key to health insurance",
    offset: "1",
    id: 3,
  },
];

export { Service, services };
