












































































































































































import { Component, Vue } from "vue-property-decorator";
import { push, ref } from "firebase/database";
import { Client } from "@/models/Client";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import database from "../firebase/index.js";
import emailjs from "emailjs-com";

@Component({
  components: {},
})
export default class Form extends Vue {
  private formClient: Client = {
    name: "",
    clEmail: "",
    phone: "",
    zipCode: "",
    message: "",
  };
  private snackbar = false;
  private valid = true;
  private databaseReference = ref(database, "/Clients");

  private nameRules: Array<any> = [
    (v: any) =>
      (v || "").length >= 3 || "Name must be greater than 3 characters",
  ];
  private phoneRules: Array<any> = [
    (v: any) => (v || "").length == 10 || "Number must be 10 digits",
  ];

  public writeUserInfo(): void {
    push(this.databaseReference, {
      client: this.formClient,
    })
      .then(this.resetForm())
      .catch(function (err) {
        console.log(err);
      });
  }
  private submit(): void {
    (this.$refs.form as Vue & { validate: () => boolean }).validate();
    if (this.valid == true) {
      this.sendEmail();
      this.writeUserInfo();
      this.snackbar = true;
    }
  }
  private resetForm(): any {
    this.formClient = {
      name: "",
      clEmail: "",
      phone: "",
      zipCode: "",
      message: "",
    };
    const refForm: any = this.$refs.form;
    refForm.reset();
  }

  sendEmail(): void {
    emailjs
      .sendForm(
        "service_8h44ijd",
        "template_eijqcuc",
        "#formEmail",
        "user_9veHQq91N6qGY2cMG8PYe"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  }
}
