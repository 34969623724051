



















































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class NavBar extends Vue {
  private drawer = false;
}
