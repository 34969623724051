







































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { cards } from "../models/CarCard";
import { reviews } from "../models/Reviews";

@Component({})
export default class Home extends Vue {
  cardList = cards;
  reviewList = reviews;
  rating = 5;
}
