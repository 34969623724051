


























































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { services } from "../models/ServiceCards";
import { cards } from "../models/Company";

@Component({})
export default class Services extends Vue {
  serviceList = services;
  compList = cards;
}
