import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueFbCustomerChat from "vue-fb-customer-chat";

Vue.config.productionTip = false;
Vue.use(VueFbCustomerChat, {
  page_id: null, //  change 'null' to your Facebook Page ID,
  theme_color: "#0A7CFF", // theme color in HEX
  locale: "en_US", // default 'en_US'
});

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
