interface Review {
  name: string;

  review: string;
  date: string;
  url: string;
  id: number;
}

const reviews: Review[] = [
  {
    name: "Ian Kanaby",

    review:
      "Peter is the man! He’s been my insurance agent for as long as I can remember and he generally cares about affordability and your well-being! Insure with confidence here.",
    date: "June 2019",
    url: "https://goo.gl/maps/osjCod1F3wLwZb7RA",
    id: 0,
  },
  {
    name: "Rossella Davis",

    review:
      "Peter was awesome!! Very friendly and did the best he could to save me some money on auto insurance. I def recommend this place and will go back in the future.",
    date: "January 2021",
    url: "https://goo.gl/maps/dfRn15HZBrJ5ah4MA",
    id: 1,
  },
  {
    name: "Mike Innovation Graphics & Wraps",

    review:
      "Peter and his son are a class act, professional and extremely efficient. If you need insurance, forget all these other places, Peter will disclose EVERYTHING to you, no hidden fees and will NOT compromise his integrity...",
    date: "October 2020",
    url: "https://goo.gl/maps/RemcvNX5a5Qr64ja9",
    id: 2,
  },
  {
    name: "Ricardo Gonzalez",

    review:
      "Best insurance you will ever find. With wonderful  people, and when ever you need help they will always try help u in the best way they can. Like no other insurance out there.",
    date: "September 2020",
    url: "https://goo.gl/maps/62kRAwy4jMfnzRjs8",
    id: 3,
  },
];

export { Review, reviews };
