














import { Component, Vue } from "vue-property-decorator";
import NavBar from "../src/components/NavBar.vue";
import Footer from "../src/components/Footer.vue";

@Component({
  components: {
    NavBar,
    Footer,
  },
})
export default class App extends Vue {}
