


































































































































































import { Component, Vue } from "vue-property-decorator";
@Component
export default class Footer extends Vue {
  private emailLink = "aaautoinsurance@cfl.rr.com";
  toTop(): void {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
}
