interface Card {
  title: string;
  icon: string;
  sub: string;
  id: number;
}

const cards: Card[] = [
  {
    title: "Auto",
    icon: "mdi-car",
    sub: "We have access to multiple carriers so we can tailor to your auto insurance needs.",
    id: 0,
  },
  {
    title: "Home/ Rental",
    icon: "mdi-home",
    sub: "We know home insurance and will gladly help inform you during every step.",
    id: 1,
  },
  {
    title: "Commercial",
    icon: "mdi-domain",
    sub: "Growing a business is a scary but amazing step. We can help show you options to have the best coverage.",
    id: 2,
  },
  {
    title: "Health",
    icon: "mdi-hospital-box-outline",
    sub: "We don't know what accidents are around the corner. We can help make sure your protected.",
    id: 3,
  },
];

export { Card, cards };
